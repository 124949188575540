import "./home.css";

import { useRef } from "react";
import LogoIcon from "images/logo-icon.svg";
import Logo from "images/logo.svg";
import IdeaBox from "images/boiteaidees-optimized.webp";
import IdeaBox2 from "images/boiteaidees2-optimized.webp";
import Slide from "react-reveal/Slide";

import { CSSTransition } from "react-transition-group";

function Home({ shown, onSubmit }) {
  const nodeRef = useRef(null);

  return (
    <div className="w-full min-h-screen bg-black bg-tree flex justify-center overflow-hidden relative">
      <Slide top>
        <img
          src={IdeaBox}
          className="absolute w-[545px] h-[453px] object-contain md:translate-none md:top-[40%] md:left-[-130px] hidden md:block"
          alt="Boite a mots"
        />
      </Slide>

      <img
        src={IdeaBox}
        className="absolute w-[545px] h-[453px] object-contain top-[70%] left-[50%] md:hidden -translate-x-1/2"
        alt="Boite a mots"
      />

      <Slide bottom>
        <img
          src={IdeaBox2}
          className="absolute w-[345px] h-[982px] object-cover top-0 right-0 wow slideInDown hidden md:block"
          alt="Boite a mots"
        />
      </Slide>

      <Slide left>
        <div className="absolute top-30 left-30 text-white">
          <a
            href="https://ulysse.com/?utm_source=nenousremerciezpas"
            title="Ulysse"
          >
            <img
              src={Logo}
              className="block w-[80px] h-[27px] object-contain"
            />
          </a>
        </div>
      </Slide>

      <Slide right>
        <div className="absolute top-30 right-30 text-white">
          <img
            src={LogoIcon}
            className="block w-[32px] h-[26px] object-contain"
          />
          <a
            href="https://www.youtube.com/watch?v=aAkMkVFwAoo&autoplay=1"
            target={"_blank"}
            rel="noreferrer"
            className="block text-16 font-book fixed overflow-auto underline rotate-90 origin-bottom-left translate-x-5 w-[200px]"
          >
            Ne cliquez pas ici
          </a>
        </div>
      </Slide>

      <Slide bottom>
        <div className="text-white text-center self-center">
          <CSSTransition
            nodeRef={nodeRef}
            in={!shown}
            timeout={{
              appear: 5000,
              enter: 5000,
              exit: 5000,
            }}
            classNames="my-node"
          >
            <div ref={nodeRef}>
              <div className="text-18 mb-10 md:text-24">Le générateur des</div>
              <div className="font-semibold text-40 md:text-100 leading-none">
                <span className="stroke-diagonal">meilleures</span>
                <span className="ml-[-15px] text-red italic z-10 relative">
                  pires
                </span>{" "}
                <br />
                <span>idées de mots</span>
              </div>
              <div className="text-18 mt-10 md:text-24">
                à écrire sur une carte cadeau Ulysse. 🎅
              </div>

              <button
                to="/mots"
                className="mt-30 text-18 font-medium inline-block bg-red text-white rounded-full px-35 py-20 text-center"
                onClick={() => onSubmit(true)}
              >
                C'est parti ! 🎄
              </button>
            </div>
          </CSSTransition>
        </div>
      </Slide>
    </div>
  );
}

export default Home;

import Home from "./home";
import Generator from "./generator";
import { useState } from "react";

function App() {
  const [shown, setShowStatus] = useState(false);
  return (
    <>
      <Home shown={shown} onSubmit={setShowStatus} />
      <Generator shown={shown} />
    </>
  );
}

export default App;

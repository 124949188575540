import classNames from "classnames";

function EmojiRadio({ emoji, label, value, currentValue, onChange }) {
  const checked = currentValue === value;
  return (
    <div className="w-1/4 flex flex-col justify-center items-center px-3">
      <span
        className={classNames([
          "transition-all text-50",
          { "drop-shadow-red": checked },
        ])}
      >
        {emoji}
      </span>
      <div>
        <input
          type="radio"
          checked={checked}
          onChange={() => {
            onChange(value);
          }}
          className="cursor-pointer transition-all text-80 appearance-none bg-[#1F2819] rounded-full w-[31px] h-[31px] checked:drop-shadow-red checked:bg-white border-[8px] border-[#1F2819]"
        />
      </div>
      <div className="font-regular uppercase text-10 text-center md:text-12 mt-5">
        {label}
      </div>
    </div>
  );
}

function ChoiceSelector({ title, options, value, onChange }) {
  return (
    <div className="flex rounded-[20px] bg-dark border-2 border-white/10 p-30 relative mt-30">
      <header className="absolute top-0 left-30 -translate-y-1/2">
        {title}
      </header>
      <div className="grow flex justify-between">
        {options.map((item, index) => (
          <EmojiRadio
            key={index}
            currentValue={value}
            onChange={onChange}
            emoji={item.emoji}
            label={item.label}
            value={index}
          />
        ))}
      </div>
    </div>
  );
}

export default ChoiceSelector;

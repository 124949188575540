import "./home.css";
import ChoiceSelector from "components/ChoiceSelector";

import { useEffect, useState } from "react";
import BlurredBackground from "components/BlurredBackground";
import ShareModal from "components/ShareModal";
import { useSpring, animated, useTransition, useSpringRef } from "react-spring";
import phrases from "./phrases.json";

const DEFAULT_NAME = "Léa";

function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

const OPTIONS = [
  {
    title: "Vous l'aimez ?",
    options: [
      {
        emoji: "❤️",
        label: "Énormément",
      },
      { emoji: "❤️‍🩹", label: "Beaucoup" },
      { emoji: "💔", label: "Un peu" },
      { emoji: "❤️‍🔥", label: "Pas du tout" },
    ],
  },
  {
    title: "Qui est-ce ?",
    options: [
      {
        emoji: "🌈",
        label: "Ami",
      },
      {
        emoji: "💯",
        label: "Famille",
      },
      { emoji: "🍑", label: "Lover" },
      {
        emoji: "💼",
        label: "Collègue",
      },
    ],
  },
  {
    title: "Généreux ?",
    options: [
      { emoji: "🦞", label: "Énormément" },
      { emoji: "🦪", label: "Beaucoup" },
      { emoji: "🦐", label: "Un peu" },
      { emoji: "🦀", label: "Pas du tout" },
    ],
  },
];

// use an increment to have more chances to take a unseen value
let generationIndex = [];
function next(love, who, generosity) {
  const index = (love ?? 9) * 100 + (who ?? 9) * 10 + (generosity ?? 9);
  let val = generationIndex[index];

  return (generationIndex[index] =
    val === undefined ? Math.floor(Math.random() * phrases.length) : val + 1);
}

function Generator({ shown }) {
  const [name, setName] = useState(null);
  const [love, setLove] = useState(null);
  const [who, setWho] = useState(null);
  const [generosity, setGenerosity] = useState(null);
  const [chosenPhrase, setChosenPhrase] = useState(null);
  const [giftPhrase, setGiftPhrase] = useState(null);

  const [modalStatus, setModalStatus] = useState(false);
  const debouncedName = useDebounce(name, 300);

  const styles = useSpring({
    opacity: shown ? 1 : 0,
    visibility: shown ? "visible" : "hidden",
  });

  const transRef = useSpringRef();

  const transitions = useTransition(giftPhrase, {
    ref: transRef,
    keys: null,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  useEffect(() => {
    transRef.start();
  }, [transRef, giftPhrase]);

  useEffect(() => {
    const candidates = phrases.filter((phrase) => {
      return (
        (love === null || phrase.love === love) &&
        (who === null || phrase.status === who) &&
        (generosity === null || phrase.generosity === generosity)
      );
    });

    console.log("candidates", candidates, love);

    const index = next(love, who, generosity);
    let newItem = candidates?.[index % (candidates?.length ?? 0)];

    if (newItem === undefined) {
      newItem = phrases[index % phrases.length];
    }

    setChosenPhrase(newItem.phrase);
  }, [love, who, generosity]);

  useEffect(() => {
    if (chosenPhrase) {
      let phrase = chosenPhrase;
      if (debouncedName !== null) {
        phrase = phrase.replace("$NAME", debouncedName.trim());
      } else {
        phrase = phrase.replace("$NAME", DEFAULT_NAME);
      }

      setGiftPhrase(phrase);
    }
  }, [chosenPhrase, debouncedName]);

  return (
    <animated.div
      style={styles}
      className="absolute top-0 left-0 w-screen bg-dark"
    >
      <BlurredBackground />
      {shown && (
        <>
          <div className="p-15 md:p-30 md:pt-[100px] max-w-[980px] mx-auto z-10 text-white w-full min-h-screen relative grid gap-30 grid-cols-1 md:grid-cols-2">
            <div>
              <h2 className="font-semibold text-30 mb-5 leading-[30px]">
                Dites-nous en plus :
              </h2>
              <ChoiceSelector
                title={OPTIONS[0].title}
                options={OPTIONS[0].options}
                value={love}
                onChange={setLove}
              />

              <ChoiceSelector
                title={OPTIONS[1].title}
                options={OPTIONS[1].options}
                value={who}
                onChange={setWho}
              />

              <ChoiceSelector
                title={OPTIONS[2].title}
                options={OPTIONS[2].options}
                value={generosity}
                onChange={setGenerosity}
              />
            </div>
            <div>
              <div className="text-[#839081] mb-10 leading-[30px]">
                Le prénom :
              </div>

              <input
                type={"text"}
                placeholder={DEFAULT_NAME}
                className="w-full rounded-lg px-25 py-19 text-dark mb-30 outline-none text-23 font-medium"
                onChange={(evt) => setName(evt.target.value)}
                value={name}
              />

              <div className="relative">
                <div className="text-[#839081] mb-10">
                  Le petit mot bien mérité :
                </div>
                <button
                  className="absolute top-0 right-0 bg-yellow text-15 font-medium px-15 py-10 rounded-full text-dark -rotate-12 shadow-normal"
                  onClick={() => setModalStatus(!modalStatus)}
                >
                  Partager
                </button>
              </div>

              <div className="text-40 font-semibold mb-30 min-h-[445px] relative phrase-generator-container leading-tight">
                {/* <Typewriter
                  onInit={(typewriter) => {
                    setInstance(typewriter);
                  }}
                  options={{
                    deleteSpeed: 15,
                    delay: 40,
                  }}
                /> */}

                {transitions((style, giftPhrase) => {
                  return (
                    <animated.div style={style}>{giftPhrase}</animated.div>
                  );
                })}
              </div>
              {/* <button className="bg-dark rounded-full w-full text-center py-25 text-18 font-medium mb-20">
                Re-générer un petit mot 🤞🏼
              </button> */}

              <a
                href={`https://ulysse.com/gift/checkout?type=electronic&message=${giftPhrase}&recipientName=${debouncedName??''}&utm_source=nenousremerciezpas`}
                target={"_blank"}
                rel="noreferrer"
                className="bg-red rounded-full w-full text-center py-25 text-18 font-medium inline-block"
              >
                Acheter une carte cadeau 🎄
              </a>
            </div>
          </div>
          <ShareModal
            display={modalStatus}
            onChange={setModalStatus}
            giftPhrase={giftPhrase}
          />
        </>
      )}
    </animated.div>
  );
}

export default Generator;

import ModalBG from "images/modal-bg.png";
import Twitter from "images/twitter.svg";
import Phone from "images/phone.svg";
import Mail from "images/mail.svg";

import { useSpring, animated } from "react-spring";

function ShareModal({ display, giftPhrase, onChange }) {
  const styles = useSpring({ opacity: display ? 1 : 0 });

  if (display === false) {
    return <></>;
  }
  return (
    <animated.div
      style={styles}
      className="fixed w-screen h-screen bg-dark/50 z-40 top-0 left-0 p-30 md:p-0 flex items-center justify-center"
      onClick={(e) => {
        if (e.currentTarget === e.target) {
          onChange(false);
        }
      }}
    >
      <div className="max-w-none md:max-w-[350px] rounded-[20px] bg-dark relative drop-shadow-red">
        <header className="z-0">
          <img
            src={ModalBG}
            className={
              "w-full h-[150px] md:h-auto object-cover z-0 rounded-t-[20px]"
            }
            alt=""
          />
        </header>
        <button className="block z-50 bg-yellow text-15 font-medium px-15 py-10 rounded-full text-dark -mt-20 mx-auto shadow-normal mb-25 relative">
          Partager ce mot
        </button>
        <div className="px-30 text-white text-20 text-center leading-tight">
          Tiens, voilà une idée de mot à écrire sur ta carte cadeau Ulysse :{" "}
          <span className="font-medium">{giftPhrase}</span>
        </div>
        <div className="mt-25 px-30 pb-30">
          <div className="text-14 text-white text-center mb-15">
            Partager sur :
          </div>
          <a
            href={`whatsapp://send?text=${giftPhrase} - https://nenousremerciezpas.ulysse.com/`}
            target="_blank"
            rel="noreferrer"
            className="block w-full text-center z-50 bg-[#73D467] text-15 font-medium px-15 py-12 rounded-full text-white mx-auto shadow-normal mb-10 relative"
          >
            <img src={Phone} className={"inline-block mr-5"} alt="" /> Whatsapp
          </a>

          <a
            href={`https://twitter.com/intent/tweet/?text=${giftPhrase} - https://nenousremerciezpas.ulysse.com/`}
            target="_blank"
            rel="noreferrer"
            className="block w-full text-center z-50 bg-[#5FC2ED] text-15 font-medium px-15 py-12 rounded-full text-white mx-auto shadow-normal mb-10 relative"
          >
            <img src={Twitter} className={"inline-block mr-5"} alt="" /> Twitter
          </a>

          <a
            href={`mailto:?body=${giftPhrase} - https://nenousremerciezpas.ulysse.com/`}
            target="_blank"
            rel="noreferrer"
            className="block w-full text-center z-50 bg-[#316CE2] text-15 font-medium px-15 py-12 rounded-full text-white mx-auto shadow-normalrelative"
          >
            <img src={Mail} className={"inline-block mr-5"} alt="" /> Email
          </a>
        </div>
      </div>
    </animated.div>
  );
}

export default ShareModal;
